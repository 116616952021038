<script setup lang="ts">
import { ref } from 'vue';
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { isAfter, formatDuration, intervalToDuration } from 'date-fns';
import {extendVolunteerSession} from "~/composables/queries/session";

const useSession = useSessionStore();

const defaultValue = '5 Minutes';
const open = ref(false);
const countdown = ref(defaultValue);
const route = useRoute();
async function resetTimeout() {
  if (route.fullPath.includes('/fp/') || route.fullPath.includes('/eds/')) {
    useSession.extendVolunteer();
  } else {
    useSession.extend();
  }
}

watch(
  () => useSession.togglePopup,
  () => {
    const cookie = useCookie('applicant-token');
    const token = localStorage.getItem('food-pantry-token');
    if (useSession.togglePopup && open.value == false && (cookie.value || token)) {
      open.value = true;
    } else if (useSession.togglePopup == false && open.value == true) {
      open.value = false;
    }
  }
);

watch(
  () => useSession.timer,
  () => {
    let duration = intervalToDuration({
      start: new Date(),
      end: useSession.sessionTimeout,
    });

    if (isAfter(new Date(), useSession.sessionTimeout)) {
      useSession.timeout();
    } else {
      countdown.value = formatDuration(duration, {
        delimiter: ', ',
      });
    }
  }
);

onMounted(() => {
  if (useSession.timerActive) {
    useSession.startCountdownTimer();
  }
  if (useSession.togglePopup) {
    open.value = true;
  }
});
</script>

<template>
  <div class="flex items-center justify-center">
    <TransitionRoot as="template" :show="open">
      <Dialog as="div" class="fixed inset-0 z-50 overflow-y-auto" @close="resetTimeout">
        <div class="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="ease-in duration-200"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>

          <!-- This element is to trick the browser into centering the modal contents. -->
          <span class="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">&#8203;</span>
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-middle shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6 sm:align-middle"
            >
              <div>
                <div class="mt-3 text-center sm:mt-5">
                  <DialogTitle as="h3" class="text-xl font-medium leading-6 text-gray-900">
                    Do you need more time?
                  </DialogTitle>
                  <div class="mt-2">
                    <p class="text-sm text-gray-500">Your session will expire in</p>
                    <h4>{{ countdown }}</h4>
                  </div>
                </div>
              </div>
              <div class="mt-5 sm:mt-6 flex flex-col justify-center">
                <button type="button" class="btn-primary" @click="resetTimeout">I need more time</button>
              </div>
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>
